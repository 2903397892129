import React from 'react';

const IconChevronDown = () => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.939 0.939453L8 5.87945L3.061 0.939453L0.938995 3.06145L8 10.1215L15.061 3.06145L12.939 0.939453Z"
        fill="black"
      />
    </svg>
  );
};

export default IconChevronDown;
